<script>
import listingBox from "@/components/listing-newbox.vue";
import imageSwapper from "@/components/image-swapper.vue";
import imagesManager from "@/components/listing-box-images-uploader.vue";
export default {
  components: {
    listingBox,
    imageSwapper,
    imagesManager,
  },
  props: {
    entry: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      editing: false,
    };
  },
  methods: {
    toggleEditor() {
      this.editing = !this.editing;
    },
  },
};
</script>

<template>
  <listingBox title="Imágenes">
    <template #head-right>
      <button class="button is-small is-link is-rounded" @click="toggleEditor">
        Editar
      </button>
    </template>

    <imagesManager :entry="entry" v-if="editing" @close="toggleEditor" />

    <imageSwapper v-if="entry.ltg_images.length" :images="entry.ltg_images" />

    <div v-else class="noimages">
      <b-message type="is-danger">
        <b-icon icon="exclamation-circle" />
        <span><b>Requerido: </b>El anuncio debe contener imágenes.</span>
      </b-message>
    </div>
  </listingBox>
</template>
