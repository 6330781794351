<script>
import draggable from "vuedraggable";
import _isEqual from "lodash/isEqual";
import _mapValues from "lodash/mapValues";
// import mimeUtils from "@/utils/mime-utils";
import loadImage from "blueimp-load-image";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { wpService } from "@/services/wp";
// import { sitioBus } from "@/main";
export default {
  components: {
    draggable,
  },

  props: {
    entry: {
      default: null,
      type: Object,
    },
  },

  data() {
    return {
      loader: null,
      accept: "image/png, image/jpeg",
      limitQuantity: 12,
      ordering: false,
      images: [],
      newFiles: null,
      radioButton: "",
    };
  },

  created() {
    this.images = this.entry.ltg_images;
    // this.loader = this.$sitio.loading.open({
    //   message: "testing"
    // });
    // setTimeout(() => {
    //   this.loader.message = "Doing";
    //   setTimeout(() => {
    //     this.loader.message = "something";
    //     setTimeout(() => {
    //       this.loader.message = "nice";
    //       setTimeout(() => {
    //         this.loader.message = null;
    //       }, 2000);
    //     }, 2000);
    //   }, 2000);
    // }, 2000);
  },

  methods: {
    close() {
      this.$emit("close");
    },
    startDrag() {
      // this.ordering = true;
    },
    endDrag() {
      // this.ordering = false;
      let newValue = _mapValues(this.images, "ID");
      let oldValue = _mapValues(this.entry.ltg_images, "ID");
      if (_isEqual(newValue, oldValue)) {
        console.log("is equal.");
        return;
      }
      let dataToPatch = {
        ltg_img_order: newValue,
      };
      let loading = this.$buefy.loading.open();
      wpService.listings
        .patchSingle(this.entry.ltg_id, dataToPatch)
        .then(() => {
          // this.editing = false;
          // this.$buefy.toast.open({
          //   message: "¡Variantes de precio actualizadas!",
          //   type: "is-success"
          // });
          loading.close();
        })
        .catch(err => {
          console.log(err);
          loading.close();
        });
    },
    uploadAsBlob(filename, filetype, file) {
      return wpService.listings.postListingImageAsBlob(
        this.entry.ltg_id,
        filename,
        filetype,
        file,
      );
    },
    async processFile(file, returnBlob = true) {
      // TODO: Check if browser supports next functions
      // let testing = true;
      // if (testing) return Promise.resolve(null);
      return new Promise(resolve => {
        let $this = this;
        loadImage(
          file,
          function(image) {
            if (image.type === "error") {
              $this.$buefy.toast.open({
                message: `Error al procesar imagen "${file.name}"`,
                type: "is-danger",
              });
              resolve(null);
            } else {
              let b64src = image.toDataURL(file.type, 1);
              let blob = dataURLtoBlob(b64src);
              if (blob.size >= 1000000) {
                b64src = image.toDataURL(file.type, 0.8);
              }
              resolve(returnBlob ? dataURLtoBlob(b64src) : b64src);
            }
          },
          {
            maxWidth: 800,
            maxHeight: 800,
            canvas: true,
            orientation: true,
          },
        );
      });
    },
    async addFiles(newFiles) {
      // console.log("newFiles", newFiles);
      const loader = this.$sitio.loading.open();

      let countFiles = this.entry.ltg_images.length;
      if (!newFiles.length || countFiles >= this.limitQuantity) {
        return;
      }
      for (var i = 0; i < newFiles.length; i++) {
        if (countFiles >= this.limitQuantity) {
          break;
        }

        // console.log(newFiles[i]);

        let filename = newFiles[i].name;
        let filetype = newFiles[i].type;
        // let filemime = mimeUtils.getData(newFiles[i]);

        let binary = newFiles[i];
        // console.log("binary", binary);
        binary = await this.processFile(binary);

        if (!binary) continue;
        // console.log("binary", binary);

        // console.log("binary", binary);

        loader.message = `Cargando imagen "${filename}"`;
        await this.uploadAsBlob(filename, filetype, binary).then(() => {
          countFiles++;
        });
      }
      loader.message = "Actualizando anuncio";
      // sitioBus.$emit("refetch_account_listing");
      loader.close();
    },
    removeFile(image) {
      const loading = this.$buefy.loading.open();
      wpService.listings
        .deleteListingImage(this.entry.ltg_id, image.ID)
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          loading.close();
        });
    },
    notUndefined(value) {
      return typeof value != "undefined";
    },
  },

  watch: {
    $route() {
      console.log("$route watcher");
      this.close();
    },
    entry: {
      deep: true,
      handler() {
        this.images = this.entry.ltg_images;
        console.log("Listing updated");
      },
    },
    // ordering() {
    //   this.images = this.entry.ltg_images;
    // },
    async newFiles(newFiles) {
      if (newFiles) {
        await this.addFiles(newFiles);
      }
      this.newFiles = null;
    },
  },
};
</script>

<template>
  <!-- <portal to="mudal"> -->
  <s-mudal customId="listingImagesEditor" :active="true" @close="close()">
    <div class="sitioCard">
      <div class="sitioCard__header">
        <b-button
          type="is-white"
          class="sitioCard__header__item sitioCard__header__close"
          @click="close"
        >
          <b-icon icon="times" />
        </b-button>
        <div class="sitioCard__header__title">Imagenes</div>
        <div class="sitioCard__header__item">
          <b-switch class="toggleOrdering" v-model="ordering"
            >Reordenar</b-switch
          >
        </div>
      </div>
      <div class="sitioCard__content">
        <div class="instructions" v-if="ordering">
          Arrastra las imágenes para reordenar.
        </div>
        <div class="instructions" v-else>
          Puedes cargar un total de {{ limitQuantity }} imágenes.
        </div>
        <div class="editor__previews" :class="{ ordering }">
          <div class="grid grid--skelleton">
            <div class="item" v-for="(n, index) in limitQuantity" :key="index">
              <img
                class="size-holder"
                src="/img/holders/sizeholder-square.png"
              />
              <div class="item__image"></div>
              <template v-if="!ordering">
                <b-button
                  v-if="notUndefined(entry.ltg_images[index])"
                  class="item__option"
                  type="is-danger"
                  @click.stop="removeFile(entry.ltg_images[index])"
                >
                  <b-icon icon="times" size="is-small"></b-icon>
                </b-button>
                <b-field v-else class="file">
                  <b-upload
                    :accept="accept"
                    v-model="newFiles"
                    multiple
                    native
                    drag-drop
                  >
                    <span class="button is-primary item__option">
                      <b-icon icon="plus" size="is-small"></b-icon>
                    </span>
                  </b-upload>
                </b-field>
              </template>
            </div>
          </div>
          <draggable
            class="grid grid--real"
            v-if="ordering"
            v-model="images"
            @start="startDrag"
            @end="endDrag"
          >
            <div class="item" v-for="(image, index) in images" :key="index">
              <img
                class="size-holder"
                src="/img/holders/sizeholder-square.png"
              />
              <div
                class="item__image"
                v-if="image"
                :style="{ backgroundImage: `url(${image.src})` }"
              >
                <svg
                  class="arrows"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                >
                  <path
                    fill="#FFFFFF"
                    d="M34.1 63.1c-1.2 1.2-3.1 1.2-4.2 0L20 53.2c-1.9-1.9-.6-5.1 2.1-5.1h6.4V35.5H15.9v6.4c0 2.7-3.2 4-5.1 2.1L.9 34.1c-1.2-1.2-1.2-3.1 0-4.2l9.9-9.9c1.9-1.9 5.1-.6 5.1 2.1v6.4h12.6V15.9h-6.4c-2.7 0-4-3.2-2.1-5.1L29.9.9C31.1-.3 33-.3 34.1.9l9.9 9.9c1.9 1.9.6 5.1-2.1 5.1h-6.4v12.6h12.6v-6.4c0-2.7 3.2-4 5.1-2.1l9.9 9.9c1.2 1.2 1.2 3.1 0 4.2L53.2 44c-1.9 1.9-5.1.6-5.1-2.1v-6.4H35.5v12.6h6.4c2.7 0 4 3.2 2.1 5.1l-9.9 9.9z"
                  />
                </svg>
              </div>
            </div>
          </draggable>
          <div class="grid grid--real" v-else>
            <div
              class="item"
              v-for="(image, index) in entry.ltg_images"
              :key="index"
            >
              <img
                class="size-holder"
                src="/img/holders/sizeholder-square.png"
              />
              <div
                class="item__image"
                v-if="image"
                :style="{ backgroundImage: `url(${image.src})` }"
              ></div>
            </div>
          </div>
        </div>
        <div class="editor__options" v-if="entry.ltg_images.length >= 2">
          <!-- <b-field>
            <b-radio-button
              v-model="ordering"
              :native-value="false"
              type="is-primary is-light is-outlined"
            >
              Agregar / Remover
            </b-radio-button>
            <b-radio-button
              v-model="ordering"
              :native-value="true"
              type="is-primary is-light is-outlined"
            >
              Organizar imágenes
            </b-radio-button>
          </b-field> -->
          <!-- <div class="notification" v-if="ordering">
            Arrastra las imágenes para reordenar.
          </div>
          <div class="notification" v-else>
            Puedes cargar un total de {{ limitQuantity }} imágenes.
          </div> -->
          <!-- <b-button size="is-info" @click="ordering = !ordering">{{
              ordering ? "Agregar / Remover" : "Organizar imágenes"
            }}</b-button> -->
          <!-- <b-button size="is-info" @click="ordering = !ordering"
              >Organizar imágenes</b-button
            > -->
        </div>
      </div>
      <!-- <div class="sitioCard__footer">
        <div class="sitioCard__footer__item">
          <b-button @click="close()">Cerrar</b-button>
        </div>
      </div> -->
    </div>
  </s-mudal>
  <!-- </portal> -->
</template>

<style lang="scss">
#listingImagesEditor {
  .toggleOrdering {
    flex-direction: row-reverse;
    margin: 0;
    gap: 0.5em;
    .control-label {
      padding-left: 0;
    }
  }

  .instructions {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .editor__options {
    text-align: center;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .editor__previews {
    position: relative;
  }

  .upload,
  .upload-draggable {
    width: 100%;
  }

  .item,
  .upload-draggable {
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    .item {
      position: relative;
    }
    .item__wrap {
      position: relative;
    }
    .item__image {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #eee;
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 100%;
    }
    .item__option {
      position: absolute;
      top: 0em;
      right: 0em;
      z-index: 1;
    }
    img {
      display: block;
      width: 100%;
    }
  }

  .grid--skelleton {
    width: 100%;
    .field.file {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      text-align: right;
    }
  }

  .grid--real {
    position: absolute;
    width: 100%;
    top: 0;
    .item__image {
      display: flex;
      align-items: center;
      justify-content: center;
      .arrows {
        opacity: 0.75;
        width: 30%;
      }
    }
  }

  @media (min-width: 426px) {
    .sitioMudal__content {
      width: 90%;
      padding: 1.5rem;
    }
  }

  @media (min-width: 641px) {
    .grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (min-width: 769px) {
    // .grid {
    //   grid-template-columns: repeat(5, 1fr);
    // }
    .sitioMudal__content {
      max-width: 80vmin;
    }
  }
}
</style>
