<script>
export default {
  props: {
    title: {
      default: "Box",
      type: String,
    },
  },
};
</script>

<template>
  <div class="listingBox">
    <div class="box__head">
      <div class="toggle--collapse" @click="$emit('toggle-collapse')">
        <b-icon icon="chevron-down" />
        <div class="box__title" v-if="title">{{ title }}</div>
      </div>
      <div class="right">
        <slot name="head-right"></slot>
      </div>
    </div>
    <div class="box__body">
      <slot />
    </div>
  </div>
</template>
